import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'sw-ui';

import InsuranceDialog from './insuranceDialog';
import { formatDate } from '../../../../bi/utils/formatDate';

import { ACCOUNTTEXT } from '../../../../bi/constants/account';
import { INSURANSE } from '../../../../bi/constants/company';
import COMPONENTS from '../../../../bi/constants/components';

import styles from '../../styles/company.module.scss';

class Insurance extends Component {
  static propTypes = {
    insuranceData: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  state = {
    showEditInsuranceDialog: false,
  };

  toggleEditInsurance = () => this.setState({
    showEditInsuranceDialog: !this.state.showEditInsuranceDialog,
  });

  insuranceData = () => {
    const { insuranceData: { ActiveInsurance } } = this.props;
    const { StartDate, EndDate } = ActiveInsurance;
    const createDateStart = StartDate ? formatDate(StartDate) : '';
    const createDateEnd = EndDate ? formatDate(EndDate) : INSURANSE.NOINDICATED;

    return Object.keys(ActiveInsurance).length !== 0 && (
      <div className={ styles.col }>
        <span className={ styles.name }>{ACCOUNTTEXT.FROM}</span>
        <b className={ styles.value }>{createDateStart}</b>
        <span className={ styles.name }>{ACCOUNTTEXT.TO}</span>
        <b className={ styles.value }>{createDateEnd}</b>
      </div>
    );
  };

  renderInsuranceDialog = () => {
    const { showEditInsuranceDialog } = this.state;
    const { insuranceData, onSave } = this.props;

    return showEditInsuranceDialog && (
      <InsuranceDialog
        onSave={ onSave }
        onClose={ this.toggleEditInsurance }
        insuranceData={ insuranceData }
      />
    );
  };

  renderShowEditButton = () => {
    const { endDate } = this.props.insuranceData.ActiveInsurance;

    return !endDate && (
      <Button
        label={ ACCOUNTTEXT.EDIT }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        onClick={ this.toggleEditInsurance }
      />
    );
  };

  render() {
    const { ActiveInsurance } = this.props.insuranceData;
    const insuranceValue = Object.keys(ActiveInsurance).length !== 0 ? INSURANSE.AVAILABLE : INSURANSE.UNAVAILABLE;

    return (
      <div className={ styles.change }>
        <span className={ styles.name }> {INSURANSE.CLIENTISINSURENCE} </span>
        <b className={ styles.value }>{insuranceValue}</b>
        {this.insuranceData()}
        {this.renderShowEditButton()}
        {this.renderInsuranceDialog()}
      </div>
    );
  }
}

export default Insurance;
