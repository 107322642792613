import React, { Component } from 'react';
import { COMPONENTS, MultiSelect } from 'sw-ui';
import PropTypes from 'prop-types';

import { startOfMonth, getMoment } from '../../../../bi/utils/formatDate';
import Radio from '../../../../components/radio';
import AjaxButton from '../../../../components/ajaxButton';
import RangeDatePicker from '../../../../components/RangeDatePicker';
import Checkbox from '../../../../components/checkbox';

import FILEFORMATS from '../../../../constants/fileFormats';
import { DATE } from '../../../../constants/time';

import { ACCOUNTTEXT } from '../../../../bi/constants/account';

import styles from '../../styles/company.module.scss';

const LABELS = {
  TITLE: 'Скачать акт сверки',
  CHOOSEPERIOD: 'выберите период:',
  FROM: 'От',
  TO: 'До',
  LASTDOCUMENT: 'до последнего сформированного пакета документов',
  YEARAHEAD: 'на год вперед',
  DETAIL: 'Билеты детально',
  SIGNATURE: 'с подписью и печатью',
  SELECTCOMPANY: 'Выбор компаний',
  GENERALREPORT: 'Общий акт сверки',
  SHORT_NAMES: 'Краткие имена',
  SELECTCOMPANYERROR: 'Выберите компанию для формирования общего акта сверки',
};

const REVISEPERIOD = 'revisePeriod';

class ReviseForm extends Component {
  static propTypes = {
    title: PropTypes.string,
    defaultFormat: PropTypes.string,
    companyId: PropTypes.number.isRequired,
    accountService: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired,
    onSendToEDO: PropTypes.func.isRequired,
  };

  static defaultProps = {
    defaultFormat: FILEFORMATS.PDF,
    title: LABELS.TITLE,
  };

  constructor(props) {
    super();

    const {
      account: {
        Companies,
      },
    } = props.accountService.get();

    this.state = {
      format: props.defaultFormat,
      startDate: startOfMonth(),
      endDate: getMoment(),
      type: 0,
      waitingResponse: false,
      generalWaitingResponse: false,
      isDetailed: false,
      generalReports: false,
      flagShortNames: false,
      companies: Companies,
      companiesIds: [],
      signature: false,
      disabledSendEDO: false,
    };
  }

  handleChangeDate = (field, value) => this.setState({
    [field]: value,
  });

  handleSelectCompanies = (values) => {
    this.setState({
      companiesIds: values,
    });
  }

  handleToggleWithoutDate = (field, value) => this.setState({ type: value });

  handleToggleSignature = () => this.setState({ signature: !this.state.signature });

  handleToggleDetailed = () => this.setState({ isDetailed: !this.state.isDetailed });

  handleToggleGeneralReport = () => {
    const { companies } = this.state;

    this.setState({
      generalReports: !this.state.generalReports,
      companiesIds: [companies[0].CompanyId],
    });
  }

  handleFlagShortNames = (shortNames) => {
    this.setState({
      flagShortNames: shortNames,
    });
  }

  handleDownload = () => {
    const { type, startDate, endDate, format, isDetailed, companiesIds, signature } = this.state;
    const { onDownload, companyId } = this.props;

    const ids = companiesIds.length ? companiesIds : [companyId];

    this.setState({
      waitingResponse: true,
    });

    if (type) {
      return onDownload('', '', type, format, isDetailed, ids, signature);
    }

    return onDownload(startDate.format(DATE), endDate.format(DATE), type, format, isDetailed, ids, signature);
  };

  handleSendToEDO = () => {
    const { type, startDate, endDate, format, isDetailed, companiesIds, signature } = this.state;
    const { onSendToEDO, companyId } = this.props;

    const ids = companiesIds.length ? companiesIds : [companyId];

    this.setState({
      disabledSendEDO: true,
    });

    if (type) {
      return (
        onSendToEDO({
          startDate: '',
          endDate: '',
          type,
          format,
          isDetailed,
          companiesIds: ids,
          signature,
        })
          .finally(() => {
            this.setState({
              disabledSendEDO: false,
            });
          })
      );
    }

    return (
      onSendToEDO({
        startDate: startDate.format(DATE),
        endDate: endDate.format(DATE),
        type,
        format,
        isDetailed,
        companiesIds: ids,
        signature,
      })
        .finally(() => {
          this.setState({
            disabledSendEDO: false,
          });
        })
    );
  }

  handleChangeFormat = (format) => {
    this.setState({ format });

    if (format === FILEFORMATS.XLSX) {
      this.setState({
        signature: false,
      });
    }
  }

  getListСompaniesNames = (companies) => {
    const { flagShortNames } = this.state;

    if (flagShortNames) {
      return (companies.reduce((acc, key) => [
        ...acc,
        {
          label: key.ShortCompanyName || key.CompanyName,
          value: key.CompanyId,
        },
      ], [])
      );
    }
    return (
        companies.reduce((acc, key) => [
          ...acc,
          {
            label: key.CompanyName,
            value: key.CompanyId,
          },
        ], [])
    );
  }

  renderGeneralReportCheckbox = () => {
    const { generalReports, flagShortNames, companies } = this.state;

    const showCheckBoxGeneralReport = companies.length > 1;

    return showCheckBoxGeneralReport && (
      <div>
        <div className={ `${styles.revise__radio} ${styles.revise__margin_top}` }>
          <Checkbox
            field={ LABELS.GENERALREPORT }
            value={ generalReports }
            label={ LABELS.GENERALREPORT }
            onChange={ this.handleToggleGeneralReport }
          />
          <Checkbox
            field={ LABELS.SHORT_NAMES }
            value={ flagShortNames }
            label={ LABELS.SHORT_NAMES }
            onChange={ () => this.handleFlagShortNames(!flagShortNames) }
          />
        </div>
      </div>
    );
  }

  renderCompanies = () => {
    const { companies, companiesIds } = this.state;

    return (
      <div className={ styles.filter }>
        <MultiSelect
          label={ LABELS.SELECTCOMPANY }
          selectAll
          items={ this.getListСompaniesNames(companies) }
          values={ companiesIds }
          onChange={ this.handleSelectCompanies }
        />
      </div>
    );
  }

  render() {
    const { startDate,
      endDate,
      type,
      waitingResponse,
      format,
      isDetailed,
      generalReports,
      signature,
      companiesIds,
      disabledSendEDO,
    } = this.state;
    const { title } = this.props;

    const generalReportsCompanies = generalReports && this.renderCompanies();
    const canDownload = generalReportsCompanies && !companiesIds.length;
    const errorSelectCompany = canDownload && (
      <div className={ styles.error__text }>{LABELS.SELECTCOMPANYERROR}</div>
    );

    return (
      <div className={ styles.revise__wrap }>
        <div className={ styles.revise__title }>{title}</div>
        <div className={ `${styles.revise__row} ${styles['revise__row-date']}` }>
          <div className={ styles.revise__radio }>
            <Radio
              className={ styles.revise__radiobutton }
              label={ LABELS.CHOOSEPERIOD }
              checked={ Number(type) === 0 }
              field={ REVISEPERIOD }
              value={ 0 }
              onChange={ this.handleToggleWithoutDate }
            />
          </div>
          <div className={ styles.revise__date }>
            <RangeDatePicker
              startDate={ startDate }
              endDate={ endDate }
              onChange={ this.handleChangeDate }
              placeholderNameFrom={ ACCOUNTTEXT.FROM }
              placeholderNameTo={ ACCOUNTTEXT.TO }
              disabledTo={ Number(type) > 0 }
              disabledFrom={ Number(type) > 0 }
            />
          </div>
        </div>
        <div className={ `${styles.revise__row} ${styles.revise__radio}` }>
          <Radio
            className={ styles.revise__radiobutton }
            label={ ACCOUNTTEXT.ALLTIME }
            checked={ Number(type) === 1 }
            field={ REVISEPERIOD }
            value={ 1 }
            onChange={ this.handleToggleWithoutDate }
          />
        </div>
        <div className={ `${styles.revise__row} ${styles.revise__radio}` }>
          <Radio
            className={ styles.revise__radiobutton }
            label={ LABELS.LASTDOCUMENT }
            checked={ Number(type) === 2 }
            field={ REVISEPERIOD }
            value={ 2 }
            onChange={ this.handleToggleWithoutDate }
          />
        </div>
        <div className={ `${styles.revise__row} ${styles.revise__between}` }>
          <div className={ styles.revise__radio }>
            <Radio
              className={ styles.revise__radiobutton }
              label={ LABELS.YEARAHEAD }
              checked={ Number(type) === 3 }
              field={ REVISEPERIOD }
              value={ 3 }
              onChange={ this.handleToggleWithoutDate }
            />
            { this.renderGeneralReportCheckbox() }
            { generalReportsCompanies }
            { errorSelectCompany }
          </div>
          <div className={ styles.revise__upd }>
            <div className={ `${styles.revise__radio} ${styles.revise__margin}` }>
              <Checkbox
                field={ LABELS.DETAIL }
                value={ isDetailed }
                label={ LABELS.DETAIL }
                onChange={ this.handleToggleDetailed }
              />
              <Checkbox
                field={ LABELS.SIGNATURE }
                value={ signature }
                label={ LABELS.SIGNATURE }
                onChange={ this.handleToggleSignature }
                disabled={ format === FILEFORMATS.XLSX }
              />
            </div>
            <div className={ `${styles.revise__radio} ${styles.revise__margin}` }>
              <Radio
                className={ styles.revise__radiobutton }
                label={ FILEFORMATS.PDF }
                checked={ format === FILEFORMATS.PDF }
                field={ REVISEPERIOD }
                value={ FILEFORMATS.PDF }
                onChange={ () => this.handleChangeFormat(FILEFORMATS.PDF) }
              />
            </div>
            <div className={ styles.revise__radio }>
              <Radio
                className={ styles.revise__radiobutton }
                label={ FILEFORMATS.XLSX }
                checked={ format === FILEFORMATS.XLSX }
                field={ REVISEPERIOD }
                value={ FILEFORMATS.XLSX }
                onChange={ () => this.handleChangeFormat(FILEFORMATS.XLSX) }
              />
            </div>
          </div>
        </div>
        <div className={ styles.revise__action }>
          <AjaxButton
            label={ ACCOUNTTEXT.DOWNLOAD }
            loading={ waitingResponse }
            disabled={ canDownload }
            onClick={ () => this.handleDownload(false) }
          />
          <AjaxButton
            label={ ACCOUNTTEXT.SEND_TO_EDO }
            loading={ disabledSendEDO }
            disabled={ disabledSendEDO }
            theme={ COMPONENTS.BUTTON.THEME.RED }
            onClick={ () => this.handleSendToEDO() }
          />
        </div>
      </div>
    );
  }
}

export default ReviseForm;
