import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'sw-ui';

import { ACCOUNTTEXT } from '../../../../bi/constants/account';

import MoneyFormat from '../../../../bi/utils/money';
import { dateWithInterval } from '../../../../bi/utils/formatDate';
import debtComponent from '../debt';
import CommentComponent from './comment';
import Insurance from './insurance';

import styles from '../../styles/company.module.scss';

const LABELS = {
  NO: 'Нет',
  INSURANCEHISTORY: 'История страхования:',
};

class Debts extends Component {
  static propTypes = {
    companyId: PropTypes.number.isRequired,
    companyService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      debts: {
        data,
        isLoading,
      },
      insuranceData,
    } = props.companyService.get();

    this.state = {
      data,
      isLoading,
      insuranceData,
    };
  }

  componentDidMount() {
    const { companyService, companyId } = this.props;

    this.unsubscribeFn = companyService.subscribe(this.updateState);

    companyService.loadDebts(companyId);
  }

  componentWillUnmount() {
    this.unsubscribeFn();

    this.props.companyService.resetDebts();
  }

  updateState = ({ debts: { isLoading, data }, insuranceData }) => this.setState({ isLoading, data, insuranceData });

  renderLoading = () => (
    <div className={ styles.loading_debts }>
      <Loading
        size='large'
      />
    </div>
  );

  renderHistoryItem = () => {
    const { insuranceData: { HistoryInsurances } } = this.state;

    return HistoryInsurances.map(({ EndDate, StartDate, Reason }) => (
      <div className={ styles.insurance_history_item }>
        {`${dateWithInterval(StartDate, EndDate)}, ${Reason}`}
      </div>
    ));
  };

  renderHistoryInsurances = () => {
    const { insuranceData: { HistoryInsurances } } = this.state;
    const history = HistoryInsurances.length ? this.renderHistoryItem() : LABELS.NO;

    return (
      <div className={ styles.insurance_history }>
        <div className={ styles.insurance_history_title }>{LABELS.INSURANCEHISTORY}</div>
        { history }
      </div>
    );
  };

  sendComment = async (comment = '') => {
    const { companyService: { sendComment }, companyId } = this.props;

    await sendComment(companyId, comment);
    this.setState({
      data: {
        ...this.state.data,
        Comment: comment,
      },
    });
  };

  editInsurance = (insuranceData) => {
    const { companyService: { loadDebts, saveInsurance }, companyId } = this.props;
    loadDebts(companyId);

    return saveInsurance(insuranceData);
  }

  renderContent = () => {
    const {
      state: {
        data,
        insuranceData,
      },
    } = this;

    const {
      HasDebt,
      BookingAmount,
      OverdraftPeriodInDays,
      Overdraft,
      DaysInDebt,
      Comment,
    } = data;

    const bookingAmountText = HasDebt ? ACCOUNTTEXT.NEWRESERVATIONSUNAVAILABLE
      : `${ACCOUNTTEXT.AVAILABLEFORBOOKING}: ${MoneyFormat.moneyWithDecimal(BookingAmount)}`;
    const historyInsurances = !!insuranceData.HistoryInsurances.length && this.renderHistoryInsurances();

    return (
      <div className={ styles.debts_wrap }>
        <div className={ styles.debts_info }>
          <div className={ styles.debts_info_item }>Период овердрафта: {OverdraftPeriodInDays}</div>
          <div className={ styles.debts_info_item }>Лимит: {MoneyFormat.moneyWithDecimal(Overdraft)}</div>
          <div className={ styles.debts_info_item }>{debtComponent(data)}</div>
          <div className={ styles.debts_info_item }>{bookingAmountText}</div>
          <div className={ styles.debts_info_item }>Дней просрочки: {DaysInDebt}</div>
        </div>
        <Insurance
          onSave={ this.editInsurance }
          insuranceData={ insuranceData }
        />
        {historyInsurances}
        <div className={ styles.debts_comment }>
          <CommentComponent
            text={ Comment }
            update={ this.sendComment }
          />
        </div>
      </div>
    );
  };

  render() {
    const { isLoading } = this.state;

    const content = isLoading ? this.renderLoading() : this.renderContent();

    return (
      <div className={ styles.panel }>
        {content}
      </div>
    );
  }
}

export default Debts;
