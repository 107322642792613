import { DEBT } from '../../../bi/constants/account';
import MoneyFormat from '../../../bi/utils/money';

const debtComponent = ({ HasDebt, Debt, TotalDebt }) => {
  const numDebt = MoneyFormat.moneyWithDecimal(Debt);

  if (!HasDebt) {
    return Debt > 0 ? `${DEBT.ALL}: ${numDebt}` : DEBT.NONE;
  }

  const numTotal = MoneyFormat.moneyWithDecimal(TotalDebt);
  const totalDebtText = `${DEBT.ALL}: ${numTotal}`;
  const overDebt = `(${DEBT.OVER}: ${numDebt})`;

  return `${totalDebtText} ${overDebt}`;
};

export default debtComponent;
