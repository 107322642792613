import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import { DECISIONBUTTONS } from '../../../../../bi/constants/decisionButtons';

import styles from '../styles/dialog.module.css';

const LABELS = {
  HEADER: (docName, documentNumber) => `Документ ${docName} будет удален из пакета документов ${documentNumber}.`,
  DO_YOU_CONFIRM: 'Вы подтверждаете действие?',
};

class DeleteDocumentDialog extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    packageId: PropTypes.number.isRequired,
    documentId: PropTypes.number.isRequired,
    docName: PropTypes.string.isRequired,
    documentNumber: PropTypes.string.isRequired,
    companyId: PropTypes.number.isRequired,
    loadDocuments: PropTypes.func.isRequired,
  };

  state = {
    waitingResponse: false,
  };

  handleConfirm = () => {
    const { companyService, documentId, companyId, packageId, onClose, loadDocuments } = this.props;

    companyService.documents.deleteDocument(companyId, packageId, documentId)
      .then(() => {
        loadDocuments();
        onClose();
      });
  };

  render() {
    const { onClose, docName, documentNumber } = this.props;
    const { waitingResponse } = this.state;

    return (
      <Dialog onClick={ onClose } width='500px'>
        <div className={ styles.dialog }>
          <div className={ styles.row }>
            <div className={ styles.header }>{ LABELS.HEADER(docName, documentNumber) }</div>
            <div className={ styles.header }>{ LABELS.DO_YOU_CONFIRM }</div>
          </div>
          <div className={ styles.actions }>
            <DecisionButtons
              waitingResponse={ waitingResponse }
              onSave={ this.handleConfirm }
              onCancel={ onClose }
              labelSave={ DECISIONBUTTONS.LABELS.DELETE_DOCUMENT }
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export { DeleteDocumentDialog };
